import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";

import {
  paginationHelper,
  getPaginationResponse,
} from "../utils/paginationHelper";

import { getAllCompanyTemplatesByPagination } from "./templatesRepo";
import store from "@/store";

//delete entity by id
export const deleteEntity = async (entityId) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  const [selectedEntity] = await db.entity
    .where("_id")
    .equals(entityId)
    .primaryKeys();
  if (selectedEntity) {
    await db.entity.delete(selectedEntity);
  }
};

//update single entity
export const updateEntityData = async (entityId, data) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  if(data?.id){
    delete data.id;
  }
  const [selectedEntity] = await db.entity
    .where("_id")
    .equals(entityId)
    .primaryKeys();
  if (selectedEntity) {
    await db.entity.delete(selectedEntity);
    await addSingleEntityToLocal(data);
  } else {
    await addSingleEntityToLocal(data);
  }
};

export const addSingleEntityToLocal = async (templateData) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  return db.entity.add({
    ...templateData,
    ...{ createdAt: new Date().toISOString() },
  });
};

//Fetching entities
export const fetchEntitiesByPagination = async (params) => {
  let entities;
  entities = await fetchEntitiesFromLocal(params);
  if (!entities) {
    entities = await fetchEntitiesFromDatabase(params);
  }
  return entities;
};

export const fetchEntitiesFromLocal = async (params) => {
  let collection = db.entity;
  if (params.order_by) {
    collection = collection.orderBy(params.order_by);
    if (params.order_type === "desc") {
      collection = collection.reverse();
    }
  } else {
    collection = collection.orderBy("updated_at");
    collection = collection.reverse();
  }
  const regex = new RegExp(params.search_string || "", "i");
  const standardEntity = await db.entity.get({ slug: "receiver" });

  if (
    params.existed_entity_ids ||
    (params?.groups && params.groups.length) ||
    params?.group ||
    params.entity_type ||
    !params.include_standard
  ) {
    // collection = collection.where("_id").anyOf(params.include_templates);
    let groups = [
      ...(params.groups || []),
      ...(params.group ? [params.group] : []),
    ];
    collection = collection.filter((entity) => {
      if (params?.existed_entity_ids?.length) {
        return (
          params.existed_entity_ids.includes(entity._id) &&
          (!params.search_string || regex.test(entity.name))
        );
      } else if (groups.length) {
        //need to change
        return (
          groups.some((e) => (entity.templateGroups || []).includes(e)) &&
          (!params.search_string || regex.test(entity.name))
        );
      } else if (!params.include_standard) {
        return (
          entity?._id !== standardEntity?._id &&
          (!params.search_string || regex.test(entity.name)) &&
          (!params.entity_type || entity.entity_type == params.entity_type)
        );
      } else if (params.entity_type) {
        return entity.entity_type == params.entity_type;
      }
      return true;
    });
  }

  if (params.search_string) {
    const regex = new RegExp(params.search_string, "i");
    collection = collection.filter((template) => regex.test(template.name));
  }

  const count = await collection.count();
  if (params.limit) {
    params.skip = (params.page - 1) * params.limit;
  }
  collection = paginationHelper(collection, params);
  const results = await collection.toArray();
  if (!results || !results.length) {
    return null;
  }
  return getPaginationResponse({
    page: params.page || 1,
    count,
    limit: params.limit || 10,
    skip: params.skip,
    data: results,
    data_field: "data",
    message: "Templates fetched successfully.",
  });
};
export const fetchEntitiesFromDatabase = async (params) => {
  return postAPICall("GET", `/entities`, params);
};

//fetch single entity
export const fetchEntityById = async (entity_id, isFromCk) => {
  let entity;
  entity = await fetchEntityFromLocal(entity_id);
  if (!entity) {
    entity = await fetchEntityFromDatabase(entity_id, isFromCk);
  }
  return entity;
};

export const fetchEntityFromLocal = async (entity_id) => {
  let result = await db.entity.get({ _id: entity_id });
  if (result) {
    let templates = await getAllCompanyTemplatesByPagination({
      include_templates: (result.templates || []).map((e) => e.template_id),
    });
    result.templates = result.templates.map((template) => {
      template.templateInfo = (templates.data || []).find(
        (e) => e._id == template.template_id
      );
      return template;
    });
  }
  return result;
};
export const fetchEntityFromDatabase = async (entity_id, isFromCk = true) => {
  const result = await postAPICall("GET", `/entities/${entity_id}`, {isFromCk: isFromCk});
  return result.data;
};
